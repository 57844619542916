import React, { useRef, useContext } from "react";
import Modal from "../Modal/Modal";
import { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { UserContext } from "./Body";

const useUserContext = () => useContext(UserContext);
const useBlockUser = () => {
  const [finished, setFinished] = useState(false);

  const handleFinished = () => {
    setFinished(false);
  };
  return {
    finished,
    handleFinished,
  };
};

export default function BloquearUsuario({
  name,
  closeModal = () => {},
  bloquearUsuario = () => {},
}) {
  const { finished } = useBlockUser();
  const modal = useRef(null);
  useEffect(() => {
    modal.current?.handleOpenModal();
  }, []);
  return (
    <>
      <Modal ref={modal}>
        {!finished && (
          <article
            style={{
              gap: "24px",
              paddingTop: "58px",
              paddingLeft: "94px",
              paddingRight: "86px",
              height: "454px",
              minHeight: "0px",
              width: "480px",
              minWidth: "0px",
            }}
            className="delete_user_card bg-white rounded m-auto"
          >
            <p className="card_title" style={{ marginBottom: "24px" }}>
              Confirma el bloqueo del usuario
            </p>
            <p className="card_text" style={{ marginBottom: "39px" }}>
              Al confirmar, el usuario se bloqueará en la plataforma y no podrá
              acceder a su cuenta
            </p>
            <div
              style={{
                padding: "11px 10px",
                marginBottom: "43px",
                fontSize: "12px",
              }}
              className="contianer-fluid border fw-bold border-dark rounded"
            >
              {name}
            </div>
            <div className="container-fluid p-0 d-flex gap-2">
              <button
                onClick={bloquearUsuario}
                className="button button_active"
                type="button"
              >
                Confirmar
              </button>
              <button
                className="button cancel_button"
                onClick={closeModal}
                type="button"
              >
                Cancelar
              </button>
            </div>
          </article>
        )}
        {finished && (
          <article
            style={{ gap: "24px" }}
            className="delete_user_card_success bg-white rounded m-auto d-flex flex-column"
          >
            <div
              className={
                "d-flex flex-column justify-content-start align-items-start gap-3"
              }
            >
              <h4 className={"card_title"}>Confirma la baja del usuario</h4>
              <p className={"card_text"}>
                El usuario se ha dado de baja correctamente
              </p>
              <div
                className={
                  "d-flex p-0 container-fluid justify-content-start gap-3 mt-2"
                }
              >
                <button
                  onClick={closeModal}
                  type="button"
                  className={"button button_active"}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </article>
        )}
      </Modal>
      <button
        className="d-none"
        type="button"
        onClick={bloquearUsuario}
      ></button>
    </>
  );
}
