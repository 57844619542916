import React, { useState } from "react";

const ModalCardPagesSmall = ({ children, className = "" }) => {
  return (
    <div
      className={
        "modal_container position-fixed  w-100  top-0  start-0  overflow-x-hidden overflow-y-auto min-vh-100 moblie-z-index " +
        className
      }
    >
      <div
        className="modal_card_container change_password_modal small-modal modal_twofactor_messages modal_cerrar_session modal_card_properties"
        // style={{
        //   width: "40dvw",
        //   height: "45dvh",
        //   position: "absolute",
        //   top: "50%",
        //   left: "50%",
        //   transform: "translate(-50%, -50%)",
        //   backgroundColor: '#ffffff'
        // }}
      >
        <div className="overflow-x-hidden mx-auto">
          <div className="row m-0" style={{ minWidth: "200px" }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCardPagesSmall;
