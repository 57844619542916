import React, { useState, useReducer, useEffect, useContext } from "react";
import Input from "../Inputs/Input";
import InputSelect from "../Inputs/InputSelect";
import InputToggle from "../Inputs/InputToggle";
import Loader from "../Loader/Loader";
import InputPhone from "../Inputs/InputPhone";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { createUserService, getAllRolesService } from "../../services/api";
import AssignRolesForm from "./AssignRolesForm";
import ToSkeleton from "../Skeleton/ToSkeleton";
import { TIME_ALERT } from "../../services/data";
const REGEXInputNombre = [
  {
    value: "^[a-zA-Z0-9]{1}$",
    message: "Debe contener al menos dos caracteres",
    coincidencia: false,
  },
  {
    value: "\\d",
    message: "No se permiten números",
    coincidencia: false,
  },
  {
    value: "[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\\s]",
    message: "No se permiten caracteres especiales",
    coincidencia: false,
  },
];
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;

const validateObject = (obj) => {
  if (obj.phone === "" || obj.phone?.length < 10) return false;
  return Object.values(obj).reduce((acc, value) => acc && value !== "", true);
};

const INITIAL_VALUES = {
  name: "",
  paternal_surname: "",
  maternal_surname: "",
  phone: "",
  email: "",
  roleIds: [],
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      if (action.field === "phone") {
        if (/^\d*$/.test(action.value)) {
          return {
            ...state,
            [action.field]: action.value,
          };
        }
        return {
          ...state,
        };
      }
      if (action.field === "roleIds") {
        const { roleIds } = state;
        if (roleIds.includes(action.value)) {
          return {
            ...state,
            roleIds: roleIds.filter((item) => item !== action.value),
          };
        } else {
          return {
            ...state,
            roleIds: [...roleIds, action.value],
          };
        }
      }
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const errorReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "REMOVE_FIELD":
      const { [action.field]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
};

export const handleCreateUsers = async ({
  values,
  loading,
  error,
  error_message,
  success,
  success_message,
  service,
  handleProcess,
}) => {
  try {
    loading(true);
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const response = await service(values);
    if (!response.success) {
      loading();
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    loading();
    success();
    success_message(response.message);
    if (handleProcess) handleProcess();
  } catch (err) {
    loading();
    error();
    if (error.message === "NETWORK_CONNECTION") {
      error_message(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
    } else {
      error_message("Lo sentimos ocurrió un error, intente más tarde");
    }
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};

const useCreateForm = ({ handleProcess }) => {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [errors, dispatchErrors] = useReducer(errorReducer, {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [rolCatalog, setRolCatalog] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleRolCatalog = async () => {
    try {
      const response = await getAllRolesService();
      if (!response.success) {
        console.error(response.message);
        setRolCatalog(false);
        setError(true);
        setErrorMessage(response.message);
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, TIME_ALERT);
        return;
      }
      setRolCatalog(response.data);
      return;
    } catch (error) {
      console.error("Error request");
      console.error(error);
      return;
    }
  };

  const getAllCatalogs = async () => {
    await handleRolCatalog();
  };

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };
  const resetFieldValue = (field, value) => {
    dispatch({ type: "RESET_FIELD", field, value });
  };
  const setFieldError = (field, value) => {
    dispatchErrors({ type: "SET_FIELD", field, value });
  };

  const deleteFieldError = (field) => {
    dispatchErrors({ type: "REMOVE_FIELD", field });
  };

  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = (msj) => {
    setSuccessMessage(msj);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleCheckbox = (e) => {
    const { value, name } = e.target;
    setFieldValue("roleIds", name);
  };

  const handleResetRoleIds = () => {
    resetFieldValue("roleIds", []);
  };

  const handleManualChange = (values) => {
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleSelectChange = (values) => {
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!navigator.onLine) {
      handleError();
      handleErrorMessage(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
      setTimeout(() => {
        handleError();
        handleErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    if (!isValid) {
      handleError();
      handleErrorMessage("Por favor complete todos los campos");
      setTimeout(() => {
        handleError();
        handleErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    await handleCreateUsers({
      values,
      loading: () => handleLoading(),
      error: () => handleError(),
      error_message: (msj) => handleErrorMessage(msj),
      success: () => handleSuccess(),
      success_message: (msj) => handleSuccessMessage(msj),
      service: createUserService,
      handleProcess,
    });
  };

  useEffect(() => {
    const getAllInfo = async () => {
      await getAllCatalogs();
    };
    getAllInfo();
  }, []);

  useEffect(() => {
    setIsValid(validateObject(values) && values?.roleIds.length > 0);
  }, [values]);

  useEffect(() => {
    if (values?.phone !== "" && values?.phone?.length < 10) {
      setFieldError("phone", "Formato de número incorrecto");
      setIsValid(false);
    } else {
      deleteFieldError("phone");
    }

    if (values?.email !== "" && !EMAIL_REGEX.test(values?.email)) {
      setFieldError("email", "Formato de correo incorrecto");
      setIsValid(false);
    } else {
      deleteFieldError("email");
    }
  }, [values?.phone, values?.email]);

  return {
    isValid,
    values,
    loading,
    error,
    errors,
    errorMessage,
    success,
    successMessage,
    rolCatalog,
    handleCheckbox,
    handleChange,
    handleSelectChange,
    handleSubmit,
    handleManualChange,
    handleResetRoleIds,
  };
};

export default function CreateForm({ handleProcess, handleModal }) {
  const {
    isValid,
    values,
    errors,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    rolCatalog,
    handleCheckbox,
    handleChange,
    handleSelectChange,
    handleSubmit,
    handleResetRoleIds,
  } = useCreateForm({ handleProcess });
  return (
    <>
      <article className="bg-white rounded m-auto d-flex flex-column user_create_form_container align-items-center create_user-form">
        <form
          style={{ maxWidth: "277px", gap: "10px" }}
          onSubmit={handleSubmit}
        >
          <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
            <p className="card_title" style={{ marginBottom: "23px" }}>
              Crea un nuevo usuario
            </p>
          </ToSkeleton>
          <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
            <p className="card_text" style={{ marginBottom: "44px" }}>
              Ingresa su información para crear un nuevo usuario. Le enviaremos
              un correo para su confirmación
            </p>
          </ToSkeleton>
          <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
            <Input
              onChange={handleChange}
              value={values.name}
              label="Nombres"
              id="name"
              name="name"
              type="text"
              valid={true}
              rounded={false}
              required={true}
              regex={REGEXInputNombre}
            />
          </ToSkeleton>
          <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
            <Input
              onChange={handleChange}
              value={values.paternal_surname}
              label="Apellido paterno"
              id="paternal_surname"
              name="paternal_surname"
              type="text"
              valid={true}
              rounded={false}
              required={true}
              regex={REGEXInputNombre}
            />
          </ToSkeleton>
          <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
            <Input
              onChange={handleChange}
              value={values.maternal_surname}
              label="Apellido materno"
              id="maternal_surname"
              name="maternal_surname"
              type="text"
              valid={true}
              rounded={false}
              required={true}
              regex={REGEXInputNombre}
            />
          </ToSkeleton>
          <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
            <InputPhone
              onChange={handleChange}
              value={values.phone}
              label="Teléfono"
              id="phone"
              name="phone"
              type="tel"
              valid={!!!errors?.phone}
              rounded={false}
              tooltip={true}
              required={true}
            />
          </ToSkeleton>
          <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
            <Input
              onChange={handleChange}
              value={values.email}
              label="Correo electrónico"
              id="email"
              name="email"
              type="email"
              valid={!!!errors?.email}
              rounded={false}
              tooltip={true}
              required={true}
            />
          </ToSkeleton>
          <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
            <InputToggle
              label="Permisos"
              value={values.roleIds[0] ?? ""}
              description={values.roleIds
                .map((rolId) => rolCatalog.find(({ id }) => id === rolId))
                .reduce(
                  (label, currentValue) =>
                    `${label !== "" ? `${label}-` : label}${currentValue.name}`,
                  ""
                )}
              required={true}
            >
              <AssignRolesForm
                handleResetRoleIds={handleResetRoleIds}
                handleCheckbox={handleCheckbox}
                roles={rolCatalog}
                values={values.roleIds}
              />
            </InputToggle>
          </ToSkeleton>

          <div style={{ marginTop: "40px" }} className="d-flex gap-3">
            <ToSkeleton loading={!rolCatalog} style={{ width: "100%" }}>
              <button
                disabled={!isValid || Object.keys(errors)?.length > 0}
                className={`button ${
                  isValid && !(Object.keys(errors)?.length > 0)
                    ? "button_active"
                    : "button_disabled"
                }`}
                type="submit"
              >
                Crear usuario
              </button>
            </ToSkeleton>
            <button
              onClick={handleModal}
              className='cancel_button'
data-testid={"cancel_button"}
              type="button"
            >
              Cancelar
            </button>
          </div>
        </form>
      </article>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
