import React, { useRef, useContext } from "react";
import Modal from "../Modal/Modal";
import { useState, useEffect } from "react";
import { deleteUserById } from "../../services/api";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import { UserContext } from "./Body";
import { TIME_ALERT } from "../../services/data";

const useUserContext = () => useContext(UserContext);

export const handleDelete = async ({
  id,
  loading,
  error,
  error_message,
  success,
  success_message,
  service,
  handleFinished,
  status,
}) => {
  try {
    loading();
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const response = await service(id, status);
    if (!response.success) {
      loading();
      error();
      error_message(response.message || "Error inesperado, intente nuevamente");
      setTimeout(() => {
        error();
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    loading();
    handleFinished();
    /*success();
    success_message(response.message);
    
    setTimeout(() => {
      success();
      success_message("");
    }, TIME_ALERT);*/
  } catch (err) {
    loading();
    error();
    if (error.message === "NETWORK_CONNECTION") {
      error_message(
        "No hay conexión a Internet. Por favor, verifica tu conexión."
      );
    } else {
      error_message("Lo sentimos ocurrió un error, intente más tarde");
    }
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};

const useDeleteUsers = ({ id, status }) => {
  const [idUser, setIdUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [finished, setFinished] = useState(false);
  const { handleRefreshData } = useUserContext();

  const handleFinished = () => {
    setFinished((prevState) => !prevState);
  };
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };
  const handleError = () => {
    setError((prevState) => !prevState);
  };
  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };
  const handleSuccessMessage = (msj) => {
    setSuccessMessage(msj);
  };

  const deleteUser = async () => {
    await handleDelete({
      id: idUser,
      service: deleteUserById,
      loading: () => handleLoading(),
      error: () => handleError(),
      error_message: (msj) => handleErrorMessage(msj),
      success: () => handleSuccess(),
      success_message: (msj) => handleSuccessMessage(msj),
      handleFinished,
      status: status,
    });
  };

  useEffect(() => {
    setIdUser(id);
  }, [id]);

  return {
    idUser,
    finished,
    deleteUser,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    setFinished,
    handleRefreshData,
  };
};

export default function DeleteUser({
  id,
  name,
  deleteButtonRef,
  status,
  finishedDeleteUser,
}) {
  const modalRef = useRef(null);
  const {
    finished,
    deleteUser,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    setFinished,
    handleRefreshData,
  } = useDeleteUsers({ id, status });
  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
    setFinished(false);
  };

  return (
    <>
      <Modal ref={modalRef}>
        {!finished && (
          <article
            style={{
              gap: "24px",
              paddingTop: "58px",
              paddingLeft: "94px",
              paddingRight: "86px",
              height: "398px",
              minHeight: "0px",
              width: "477px",
              minWidth: "0px",
            }}
            className="delete_user_card bg-white rounded m-auto"
          >
            <p className="card_title" style={{ marginBottom: "24px" }}>
              Confirma la baja del usuario
            </p>
            <p className="card_text" style={{ marginBottom: "39px" }}>
              Al confirmar, el usuario se dará de baja de la plataforma y no
              podrá acceder a su cuenta
            </p>
            <div
              style={{
                padding: "11px 10px",
                marginBottom: "43px",
                fontSize: "12px",
              }}
              className="contianer-fluid border fw-bold border-dark rounded"
            >
              {name}
            </div>
            <div className="container-fluid p-0 d-flex gap-2">
              <button
                onClick={deleteUser}
                className="button button_active"
                type="button"
              >
                Confirmar
              </button>
              <button
                className="button cancel_button"
                onClick={handleModal}
                type="button"
              >
                Cancelar
              </button>
            </div>
          </article>
        )}
        {finished && (
          <article
            style={{ gap: "24px" }}
            className="delete_user_card_success bg-white rounded m-auto d-flex flex-column"
          >
            <div
              className={
                "d-flex flex-column justify-content-start align-items-start gap-3"
              }
            >
              <h4 className={"card_title"}>Confirma la baja del usuario</h4>
              <p className={"card_text"}>
                El usuario se ha dado de baja correctamente
              </p>
              <div
                className={
                  "d-flex p-0 container-fluid justify-content-start gap-3 mt-2"
                }
              >
                <button
                  onClick={() => {
                    handleModal();
                    finishedDeleteUser();
                  }}
                  type="button"
                  className={"button button_active"}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </article>
        )}
      </Modal>
      <button
        className="d-none"
        type="button"
        onClick={handleModal}
        ref={deleteButtonRef}
      ></button>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
