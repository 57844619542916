import React, { useCallback } from "react";

export default function Header({
  columns,
  header_styles,
  header_classname,
  handleSort,
  sortConfig,
}) {
  const handleColumnClick = useCallback(
    async ({ key_name, service, order }) => {
      if (key_name) {
        if (service) await service({ sort_key: key_name, order });
        handleSort(key_name);
      }
    },
    [handleSort]
  );

  return (
    <thead>
      <tr
        {...(header_classname
          ? { className: header_classname }
          : { className: 'table_header' })}
        {...(header_styles && { style: header_styles })}
      >
        {columns.map(({ name, style, className, ...props }, index) => (
          <th
            onClick={() =>
              props.key_name &&
              handleColumnClick({
                key_name: props.key_name,
                service: props.service,
                order: sortConfig.order,
              })
            }
            data-testid={`${props.key_name}-head-col`}
            scope="col"
            key={`table-header-cell-${index}`}
            className={`
              ${
                sortConfig?.orderBy === props.key_name
                  ? 'dco_column_sort_selected'
                  : ''
              }
              ${
                props.key_name
                  ? sortConfig?.orderBy === props.key_name
                    ? sortConfig.order === 'asc'
                      ? 'sortable_column_asc'
                      : 'sortable_column_desc'
                    : 'sortable_column_desc'
                  : ''
              }
              ${className ? `${className}` : 'table_header_cell'}
              `}
            {...(style && { style })}
          >
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );
}
