import React from 'react'

export default function Checkbox({ id, name, label, value, rounded, onChange, bold }) {
  return (
    <div style={{ gap: "13px" }} className='d-flex align-items-start'>
      <button 
        type='button' 
        style={{ marginTop: "3.5px" }} 
        className={`
        position-relative input_checkbox_button
        ${value ? "input_checkbox_button_active" : "input_checkbox_button_inactive"}
        ${rounded ? "rounded-circle": ""}
      `}>
        <input 
          type="checkbox" 
          id={id}
          name={name}
          value={value}
          className="input_checkbox"
          onChange={onChange}
          data-testid={id}
        />
      </button>
      {label && <p className={`input_checkbox_label ${bold ? "fw-bold": ""}`}>{label}</p>}
    </div>
  )
}
