import React, { useState, useEffect } from "react";
import ModalCardPagesSmall from "../Modal/ModalCardPagesSmall";
import ModalCardMedPages from "../Modal/ModalCardMedPages";
import { activarTwoFactor, enviarCodigoTwoFactor } from "../../services/api";
import AlertaGeneral from "../Alerts/AlertaGeneral.jsx";
import FormTwoFactor from "./FormTwoFactor.jsx";
import Loader from "../Loader/Loader.jsx";
import { TIME_ALERT } from "../../services/data.js";

const TwoFactor = ({
  activar = true,
  email,
  cerrarModal = () => {},
  userName = "",
  codeValid,
}) => {
  const [pedirCodigo, setPedirCodigo] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [alertsuccess, setAlertsuccess] = useState("");
  const [intentos, setIntentos] = useState(0);
  const [loading, setLoading] = useState(false);
  const limiteIntentos = 3;
  const tiempoEsperaMs = 15 * 60 * 1000;
  const [codInvalido, setCodInvalido] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, TIME_ALERT);
    }
  }, [error]);
  useEffect(() => {
    if (intentos >= limiteIntentos) {
      sessionStorage.setItem("bloqueoCodigoChangePassword", "true");
      setTimeout(() => {
        sessionStorage.setItem("bloqueoCodigoChangePassword", "false");
      }, tiempoEsperaMs);
    }
  }, [intentos, tiempoEsperaMs]);
  useEffect(() => {
    let bloqueo = sessionStorage.getItem("bloqueoCodigoChangePassword");
    if (bloqueo == "true") {
      setIntentos(limiteIntentos + 1);
      setPedirCodigo(true);
    }
  }, []);

  const activarTwoFactorClick = async (reenvio = false) => {
    try {
      if (!navigator.onLine) {
        setError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        setTimeout(() => {
          setError("");
        }, TIME_ALERT);
        return;
      }
      setLoading(true);
      let res = await activarTwoFactor(email, activar);
      let result = await res.json();
      if (result.isSucceed) {
        setPedirCodigo(true);
        if (reenvio === true) {
          setAlertsuccess("El envío del código se ha realizado con éxito");
          setTimeout(() => {
            setAlertsuccess("");
          }, TIME_ALERT);
        }
      } else {
        setError("Problema al solicitar la activación");
      }
    } catch (error) {
      setError("Problema al solicitar la activación");
    } finally {
      setLoading(false);
    }
  };
  const enviarCodigo = async () => {
    if (!navigator.onLine) {
      setError("No hay conexión a Internet. Por favor, verifica tu conexión.");
      setTimeout(() => {
        setError("");
      }, TIME_ALERT);
      return;
    }
    setCodInvalido(false);
    try {
      setLoading(true);
      let res = await enviarCodigoTwoFactor(codigo, email, activar);
      let result = await res.json();
      if (result.isSucceed) {
        setSuccess(true);
        cerrarModal(true);
      } else {
        setError(
          "El código ingresado es incorrecto o ha expirado. Intentos restantes " +
            (limiteIntentos - intentos - 1)
        );
        setIntentos((prevState) => prevState + 1);
        setCodInvalido(true);
      }
    } catch (error) {
      setError("Problema al solicitar la activación");
    } finally {
      setLoading(false);
    }
  };
  const cancelar = async () => {
    cerrarModal();
  };
  const reenviarCodigo = async () => {
    if (!navigator.onLine) {
      setError("No hay conexión a Internet. Por favor, verifica tu conexión.");
      setTimeout(() => {
        setError("");
      }, TIME_ALERT);
      return;
    }

    if (!alertsuccess) {
      activarTwoFactorClick(true);
      /*setAlertsuccess("Código Reenviado");
      setTimeout(() => {
        setAlertsuccess("");
      }, 3000);*/
    }
  };

  return (
    <>
      {!pedirCodigo ? (
        <ModalCardPagesSmall className="two_factor_modal_class">
          <div className="overflow-x-hidden modal-activate-twofactor">
            <div
              className="row m-0 mb-4 container-mjs-twofactor"
              style={{ minWidth: "100%" }}
            >
              <div className="d-flex flex-column h-100 justify-content-between p-0">
                <h4 className="card_title" style={{ marginBottom: "10px" }}>
                  {activar ? "Activa" : "Desactiva"} el doble factor de
                  autenticación
                </h4>
                <p className="card_text mb-4 mt-3">
                  {activar
                    ? `Por tu seguridad, si activas esta
                  opción enviaremos un código a tu correo electrónico cada vez
                  que inicies sesión`
                    : `Vamos a desactivar la segunda capa de seguridad en tu cuenta, si estas de acuerdo da clic en el botón de confirmar`}
                </p>
                <div className="container-fluid p-0 d-flex gap-1">
                  <button
                    type="submit"
                    className="button button_active"
                    style={{ height: "fit-content" }}
                    onClick={activarTwoFactorClick}
                  >
                    {activar ? "Activar ahora" : "Confirmar"}
                  </button>
                  <button
                    type="button"
                    className='cancel_button'
data-testid={"cancel_button"}
                    style={{ height: "fit-content" }}
                    onClick={cancelar}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalCardPagesSmall>
      ) : intentos < limiteIntentos ? (
        <ModalCardMedPages>
          <FormTwoFactor
            setCodigo={setCodigo}
            reenviarCodigo={reenviarCodigo}
            cancelar={cancelar}
            enviarCodigo={enviarCodigo}
            activar={activar}
            userName={userName}
            codeValid={codInvalido}
          />
          {/* <Help /> */}
        </ModalCardMedPages>
      ) : (
        <ModalCardMedPages typeModal={true}>
          <div
            className="overflow-x-hidden mx-auto"
            style={{
              // minWidth: "600px",
              // minHeight: "560px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="row m-0"
              style={{
                width: "60%",
                height: "60%",
                maxWidth: "279px",
              }}
            >
              <div className="d-flex flex-column h-100 justify-content-around">
                <div className="card_title">
                  <img
                    src="/images/dicio/logo.webp"
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
                <p className="card_text">
                  Haz excedido el número de intentos máximos de ingreso para el
                  código. Espera <bold className="fw-bold">15 minutos</bold>{" "}
                  para realizar un nuevo intento
                </p>
                <div className="container-fluid p-0 d-flex gap-1 justify-content-between">
                  <button
                    type="button"
                    className="button button_active"
                    style={{ height: "fit-content" }}
                    onClick={cancelar}
                  >
                    Regresar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalCardMedPages>
      )}
      {error && <AlertaGeneral type={"error"}>{error}</AlertaGeneral>}
      {alertsuccess && !error && (
        <AlertaGeneral type={"success"}>{alertsuccess}</AlertaGeneral>
      )}
      {loading && <Loader />}
    </>
  );
};

export default TwoFactor;
