import React from "react";
import { Permisos } from "../../services/permisos";

const LimpiarFiltros = ({ INITIAL_VALUES, handleChange, modulo = "" }) => {
  const handleLimpiarFiltros = () => {
    for (const key in INITIAL_VALUES) {
      if (Object.prototype.hasOwnProperty.call(INITIAL_VALUES, key)) {
        handleChange({ name: key, value: INITIAL_VALUES[key] });
      }
    }
  };
  return (
    (Permisos.verificarPermiso(modulo, "Buscar") || !modulo) && (
      <div>
        <button data-testid="clear_filters" className="dco_button_limpiar" onClick={handleLimpiarFiltros}>
          Limpiar filtros
        </button>
      </div>
    )
  );
};

export default LimpiarFiltros;
