import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useCallback,
  useRef,
} from "react";
import Table from "../Table/Container";
import {
  exportBitacoraService,
  getAllLogsService,
  getCatalogs,
} from "../../services/api";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import {
  useLoaderReducer,
  useErrorReducer,
  useValuesReducer,
  handleGetCatalogs,
  useSucceedReducer,
  validarFecha,
  getDefaultInitialDateValues,
} from "../../services/data";
import ExportData from "./ExportData";
import FilterForm from "./FilterForm";
import { Permisos } from "../../services/permisos";
import { saveAs } from "file-saver";
import Pagination from "../Table/Pagination";
import usePaginationBack from "../Table/usePaginationBack";
import LimpiarFiltros from "../Sharing/LimpiarFiltros";
export const ActivityContext = createContext();

export const handleExportActivity = async ({
  values,
  service,
  loading,
  error,
}) => {
  try {
    loading("increment");
    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }
    const response = await service(values);
    if (!response.success) {
      loading("decrement");
      error(response.message);
      console.error("Error request");
      return;
    }
    loading("decrement");
    return response.data;
  } catch (err) {
    loading("decrement");
    if (error.message === "NETWORK_CONNECTION") {
      error("No hay conexión a Internet. Por favor, verifica tu conexión.");
      return;
    }
    error("Lo sentimos ocurrió un error, intente más tarde");
    console.error("Error request");
    console.error(err);
    return;
  }
};

export const handleGetAllLogs = async ({
  values,
  service,
  loading,
  error,
  handleValidateTotalPages,
  setTableData,
  signal,
}) => {
  let loaderDecremented = false;

  try {
    if (signal.current) {
      signal.current.abort();
    }

    if (!navigator.onLine) {
      throw new Error("NETWORK_CONNECTION");
    }

    signal.current = new AbortController();

    loading("increment");

    const response = await service({
      ...values,
      signal: signal.current.signal,
    });

    if (response.name === "AbortError") {
      return;
    }

    if (!response.success) {
      error(response.message);
      console.error("Error request");
      return;
    }
    handleValidateTotalPages({
      totalPages: response.pagination.TotalPage,
      page: response.pagination.CurrentPage,
      totalElements: response.pagination.TotalCount,
    });
    setTableData(response.data);
  } catch (err) {
    if (err.message === "NETWORK_CONNECTION") {
      error("No hay conexión a Internet. Por favor, verifica tu conexión.");
    } else if (err.name !== "AbortError") {
      error("Lo sentimos ocurrió un error, intente más tarde.");
      console.error("Error request");
      console.error(err);
    }
  } finally {
    if (!loaderDecremented) {
      loading("decrement");
      loaderDecremented = true;
    }
  }
};

const INITIAL_VALUES = {
  search: "",
  ...getDefaultInitialDateValues(),
  type_event: "",
};

const mappingOrderKeys = {
  usuario: "Usuario",
  fechaHora: "FechaHora",
  tipoEvento: "TipoEvento",
  datosRegistrados: "DatosRegistrados",
};

const base64ToBlob = (base64, mime) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
};

export default function Body() {
  const { errorValues, handleError } = useErrorReducer();
  const { loaderValues, handleLoader } = useLoaderReducer();
  const { succeedValues, handleSucceed } = useSucceedReducer();
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [eventLogsOptions, setEventLogsOptions] = useState([]);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(new AbortController());
  const [orderConfig, setOrderConfig] = useState({
    orderBy: "fechaHora",
    order: "asc",
  });
  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();

  // Handlers
  const handleChange = createHandleChange(handleResetPagination);

  const handleDatePickerChange = createHandleDatePickerChange(
    handleResetPagination
  );

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  const getLogs = async () => {
    await handleGetAllLogs({
      values: {
        current_page: pagination.page,
        limit: true,
        ...values,
        OrderAscending: orderConfig.order,
        OrderBy: mappingOrderKeys[orderConfig.orderBy],
      },
      signal: abortControllerRef,
      loading: handleLoader,
      error: handleError,
      handleValidateTotalPages,
      setTableData,
      service: getAllLogsService,
    });
  };

  useEffect(() => {
    handleGetCatalogs({
      service: getCatalogs,
      setter: setEventLogsOptions,
      catalog: "event_logs",
      error: handleError,
    });
  }, []);

  // Effects
  useEffect(() => {
    if (validarFecha(values, handleError)) getLogs();
    else setTableData([]);
  }, [values, orderConfig, pagination.page]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const handleExport = async () => {
    const data = await handleExportActivity({
      values: {
        limit: false,
        ...values,
        total_registers: pagination.TotalCount,
        OrderAscending: orderConfig.order,
        OrderBy: mappingOrderKeys[orderConfig.orderBy],
      },
      loading: handleLoader,
      error: handleError,
      service: getAllLogsService,
    });
    if (data) return data;
    else throw new Error("");
  };
  const handleDownloadPdf = async (type) => {
    try {
      if (!navigator.onLine) {
        handleError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        return;
      }

      handleLoader("increment");

      const response = await exportBitacoraService({
        search: values.search,
        start_date: values.start_date,
        end_date: values.end_date,
        start_hours: values.start_hours,
        end_hours: values.end_hours,
        signal: abortControllerRef.current.signal,
        total_registers: pagination.TotalCount,
        type_event: values.type_event,
        OrderAscending: orderConfig.order,
        OrderBy: mappingOrderKeys[orderConfig.orderBy],
        type
      });
      if (!response.success) {
        handleError(response.message);
        return;
      }
      if (!response.data) {
        handleError("Error al exportar");
        return;
      }

      const blobFile = base64ToBlob(response.data, `application/${type}`);
      saveAs(blobFile, `Bitacora.${type}`);
      handleSucceed("La exportación de datos se ha realizado con éxito");
    } catch (error) {
      console.error("Error en handleDownloadPdf:", error.message);
      handleError(error.message || "Ocurrió un error al exportar");
    } finally {
      handleLoader("decrement");
      throw new Error("");
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Usuario",
        selector: (row) => row?.usuario ?? "",
        key_name: "usuario",
        service: handleOrderByColumns
      },
      {
        name: "Fecha/Hora",
        selector: (row) =>
          dayjs
            .utc(row?.fechaHora)
            .utcOffset(-6)
            .format("DD/MM/YYYY HH:mm:ss [(GMT-6)]"),
        key_name: "fechaHora",
        service: handleOrderByColumns
      },
      {
        name: "Tipo de evento registrado",
        selector: (row) => row?.tipoEvento ?? "",
        key_name: "tipoEvento",
        service: handleOrderByColumns
      },
      {
        name: "Datos registrados",
        selector: (row) => row.datosRegistrados,
        key_name: "datosRegistrados",
        service: handleOrderByColumns
      },
    ],
    [handleOrderByColumns]
  );

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    orderConfig,
    pagination,
  ]);

  return (
    <ActivityContext.Provider
      value={{
        eventLogsOptions,
        handleExport,
        handleDownloadPdf,
      }}
    >
      <div className="container-fluid d-flex flex-column p-0 dco_actividad_table_container">
        <div
          style={{ marginBottom: "11px" }}
          className="d-flex justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end pt-3"
        >
          {Permisos.verificarPermiso("Bitácora", "Buscar") && (
            <FilterForm {...{ handleDatePickerChange, handleChange, values }} />
          )}

          <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap">
            <LimpiarFiltros
              handleChange={handleChange}
              INITIAL_VALUES={INITIAL_VALUES}
              modulo="Bitácora"
            />
            {Permisos.verificarPermiso("Bitácora", "Exportar") && (
              <ExportData />
            )}
          </div>
        </div>
        {memoizedUserTable}
      </div>
      {loaderValues.loading > 0 && <Loader />}
      {errorValues.timeLeft > 0 && (
        <AlertaGeneral type={"error"}>{errorValues.currentError}</AlertaGeneral>
      )}
      {succeedValues.timeLeft > 0 && (
        <AlertaGeneral type={"success"}>
          {succeedValues.currentSucceed}
        </AlertaGeneral>
      )}
    </ActivityContext.Provider>
  );
}
