import React, { Fragment } from "react";
import { formatToTwoDecimals } from "../../services/data"
const Database = "/images/dashboard/dashboard_db.png";
const Arrows = "/images/dashboard/cargas.svg";
const Transactions = "/images/dashboard/dashboard_transactions.png";
const TimeAverage = "/images/dashboard/time_average.svg";
const PictureQuality = "/images/dashboard/picture_quality.svg";

export default function Statistics({ 
  registrosNodo,
  transaccionesNodo,
  no_cargas_total,
  tiempoPromedio,
  calidad_foto
}) {
  return (
    <Fragment key="stadistics_nodo">
      <div
        style={{ gap: "10px", marginTop: "23.86px", flexFlow: "wrap" }}
        className="container-fluid d-flex px-3"
      >
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div className="d-flex align-items-end" style={{ gap: "9px" }}>
            <img
              style={{ marginBottom: "0px" }}
              src={Database}
              alt="Database icon"
              width={"23.66px"}
              height={"28px"}
            />
            <p
              className="main_title"
              style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
            >
              {registrosNodo ?? "0"}
            </p>
          </div>  
          <p className="dashboard_text m-0 text_statistics_tags">
            Registros totales en el nodo
          </p>
        </div>
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div
            className="d-flex align-items-end dashboard_petitions_text_container"
            style={{ gap: "54px" }}
          >
            <div className="d-flex align-items-end" style={{ gap: "9px" }}>
              <img
                style={{ marginBottom: "2px" }}
                src={Transactions}
                alt="Database icon"
                width={"28.48px"}
                height={"20px"}
              />
              <p
                className="main_title"
                style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
              >
                {transaccionesNodo ??
                  "0"}
              </p>
            </div>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Transacciones totales en el nodo
          </p>
        </div>
      </div>

      <div
        style={{ gap: "10px", marginTop: "23.86px", flexFlow: "wrap" }}
        className="container-fluid d-flex px-3"
      >
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div className="d-flex align-items-end" style={{ gap: "9px" }}>
            <img
              style={{ marginBottom: "0px" }}
              src={Arrows}
              alt="Database icon"
              width={"32.7px"}
              height={"25.58px"}
            />
            <p
              className="main_title"
              style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
            >
              {no_cargas_total ?? "0"}
            </p>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Cargas en el nodo
          </p>
        </div>
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div className="d-flex align-items-end" style={{ gap: "9px" }}>
            <img
              style={{ marginBottom: "2px", height: "100%" }}
              src={TimeAverage}
              alt="Database icon"
              width={"28px"}
              height={"28px"}
            />
            <p
              className="main_title"
              style={{
                opacity: ".8",
                fontSize: "36px",
                lineHeight: "25px",
                alignItems: "baseline",
              }}
            >
              {tiempoPromedio ?? "0"}
              <small style={{ paddingLeft: "5px"}} className="main_title">seg</small>
            </p>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Promedio tiempo de respuesta
          </p>
        </div>
        <div className="dashboard_cards justify-content-between d-flex flex-column text_container_cards">
          <div
            className="d-flex align-items-end dashboard_petitions_text_container"
            style={{ gap: "54px" }}
          >
            <div className="d-flex align-items-end" style={{ gap: "9px" }}>
              <img
                src={PictureQuality}
                alt="Database icon"
                width={"28px"}
                height={"28px"}
              />
              <p
                className="main_title"
                style={{ opacity: ".8", fontSize: "36px", lineHeight: "28px" }}
              >
                {formatToTwoDecimals(calidad_foto) ?? "0"}
              </p>
            </div>
          </div>
          <p className="dashboard_text m-0 text_statistics_tags">
            Promedio índice de calidad de la fotografía
          </p>
        </div>
      </div> 
    </Fragment>
  );
}
