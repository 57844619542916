import React, { useEffect } from "react";
import ModalCard from "../components/Modal/ModalCard";
import CompletarRegistroForm from "../components/RegistroUsuario/CompletarRegistroForm";
import { useEnviroment } from "../services/enviromentConfig";
import { configurarVariablesEnviroment as configENV } from "../services/enviromentConfig";
const CompletarRegistro = () => {
  const { configurarEnviroment } = useEnviroment({
    controladorEnviromentObtenido: configENV,
  });
  useEffect(() => {
    configurarEnviroment(true);
  }, []);
  return (
    <div className="template_completeRegister vh-100 vw-100 d-flex justify-content-center align-items-center p-3 ">
      <ModalCard>
        <CompletarRegistroForm />
      </ModalCard>
    </div>
  );
};

export default CompletarRegistro;
