import React, {useEffect, useState} from 'react';
import './App.css';
import {
  Route,
  Routes,
  BrowserRouter, useLocation
} from "react-router-dom";
import { createBrowserHistory } from "history";
//importación de Componentes
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import RecuperarPassword from './pages/RecuperarPassword';
import Ajustes from './pages/Ajustes';
import CompletarRegistro from './pages/completarRegistro';
import PrivateRoutes from './components/Auth/PrivateRoutes';
import Users from "./pages/Users";
import PasswordRecovery from "./pages/PasswordRecovery";
import RestorePassword from './pages/RestorePassword';
import BloqueoUsuario from './pages/BloqueoUsuario';
import Management from './pages/Management';
import Transactions from './pages/Transactions';
import Actividad from './pages/Actividad';
import Nodos from './pages/Nodos';
import { configurarVariablesEnviroment as configENV} from "./services/enviromentConfig";
import "./services/api";
import { useEnviroment } from "./services/enviromentConfig";
import Loader from './components/Loader/Loader';

const history = createBrowserHistory();

function App() {
  const [configCargado, setConfigCargado] = useState(false);
  const controladorEnviromentObtenido = ()=>{
    configENV();
  }
  const {configurarEnviroment} = useEnviroment({controladorEnviromentObtenido});
  useEffect(() => {
    configurandoVariablesEntorno();
  },[window?.location?.pathname]);
  const configurandoVariablesEntorno = async ()=>{
    await configurarEnviroment();
    setConfigCargado(true);
  }
  return (
    configCargado?
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/recuperar-contrasena" element={<PasswordRecovery/>}></Route>
        <Route path="/recuperar-password" element={<RecuperarPassword/>}></Route>
        <Route path="/register-callback" element={<CompletarRegistro/>}></Route>
        <Route path="/restaurar-contrasena" element={<RestorePassword/>}></Route>
        <Route path="/bloqueo-usuario" element={<BloqueoUsuario/>}></Route>
        <Route element={<Login />} path="/change-password-expired"></Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard/>}></Route>
          <Route path="/transacciones" element={<Transactions/>}></Route>
          <Route path="/nodos" element={<Nodos/>}></Route>
          <Route path="/gestion" element={<Management/>}></Route>
          {/*<Route path="/actividad" element={<Actividad/>}></Route>*/}
          <Route path="/ajustes" element={<Ajustes/>}></Route>
          <Route path="/" element={< Login/>}></Route>
          <Route element={<Users />} path="/usuarios"></Route>
        </Route>
      </Routes>
  </BrowserRouter>
  : 
  <Loader/>
  );
}

export default App;