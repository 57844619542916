import React, { useEffect, useMemo, useRef } from "react";
import SearchInput from "../Inputs/SearchInput";
import ScoreInput from "../Inputs/ScoreInput";
import SlideWrapper from "../Sharing/SlideWrapper";
import { useTimeFilters } from "../Sharing/TimeFilters";

const formatValueLabelScore = (value) => {
  return `${value}`;
};

export default function FilterForm({
  handleDatePickerChange,
  handleChange,
  values,
  responseTimeLimits,
}) {
  const timeFilters = useTimeFilters({
    handleDatePickerChange,
    handleChange,
    values,
  });
  return (
    <form
      className="container-fluid p-0 justify-content-start d-flex gap-1 transactrions_filter_form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{ marginRight: "10px" }}
    >
      <SlideWrapper values={values}>
        <div style={{ maxWidth: "137px" }} className="container-fluid p-0 m-0">
          <SearchInput
            onChange={(e) => {
              handleChange({
                name: e.target.name,
                value: e.target.value,
              });
            }}
            value={values.search}
            label="Buscar"
            id="search"
            name="search"
            type="search"
            valid={true}
            rounded={true}
          />
        </div>
        {timeFilters}
        <div style={{ maxWidth: "170px" }} className="container-fluid p-0 m-0 ">
          <ScoreInput
            id={`photo_quality`}
            name={`photo_quality`}
            label="Índice calidad de fotografía"
            value={values.photo_quality}
            handleSetValue={({ field, value }) => {
              handleChange({
                name: field,
                value,
              });
            }}
            arrowWidth={5}
            max_width={170}
            rounded={true}
            min={0}
            max={1}
            step={0.01}
            formatValueLabelCustom={formatValueLabelScore}
          />
        </div>

        <div style={{ maxWidth: "140px" }} className="container-fluid p-0 m-0 ">
          <ScoreInput
            id={`response_time`}
            name={`response_time`}
            label="Tiempo de respuesta"
            value={values.response_time}
            handleSetValue={({ field, value }) => {
              handleChange({
                name: field,
                value,
              });
            }}
            arrowWidth={5}
            max_width={140}
            rounded={true}
            {...responseTimeLimits}
          />
        </div>
      </SlideWrapper>

      <button type="submit" className="d-none"></button>
    </form>
  );
}
