import React from "react";
import { useTimeFilters } from "../Sharing/TimeFilters";
import SlideWrapper from "../Sharing/SlideWrapper";

export default function FilterDashboard({
  values,
  handleDatePickerChange,
  handleChange,
}) {
  const timeFilters = useTimeFilters({
    handleDatePickerChange,
    handleChange,
    values,
  });
  return (
    <form
      className="container-fluid px-1 justify-content-end d-flex gap-1 gestion_filter_form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <SlideWrapper values={values}>{timeFilters}</SlideWrapper>

      <button type="submit" className="d-none"></button>
    </form>
  );
}
